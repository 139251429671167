<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <country-select />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <sector-select />
          </b-col>
          <b-col cols="12">
            <title-text />
          </b-col>
          <b-col cols="12">
            <summary-text />
          </b-col>
          <b-col cols="12">
            <content-text />
          </b-col>
          <b-col cols="12">
            <url />
          </b-col>
          <b-col cols="12">
            <ordering />
          </b-col>
          <b-col cols="12">
            <keywords-select />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col cols="12">
      <logo-card />
    </b-col>
    <b-col cols="12">
      <b-button
        variant="primary"
        :disabled="itemData.submitStatus"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'
import CountrySelect from '@/views/Admin/References/elements/CountrySelect.vue'
import SectorSelect from '@/views/Admin/References/elements/SectorSelect.vue'
import TitleText from '@/views/Admin/References/elements/Title.vue'
import SummaryText from '@/views/Admin/References/elements/Summary.vue'
import ContentText from '@/views/Admin/References/elements/ContentText.vue'
import Url from '@/views/Admin/References/elements/Url.vue'
import Ordering from '@/views/Admin/References/elements/Ordering.vue'
import KeywordsSelect from '@/views/Admin/References/elements/KeywordsSelect.vue'
import LogoCard from '@/views/Admin/References/elements/LogoCard.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    CountrySelect,
    SectorSelect,
    TitleText,
    SummaryText,
    ContentText,
    Ordering,
    KeywordsSelect,
    Url,
    LogoCard,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['references/dataItem']
    },
  },
}
</script>
