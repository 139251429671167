<template>
  <b-form-group
    label="Web Site Bağlantısı"
    label-for="url"
  >
    <b-form-input
      id="url"
      v-model="dataItem.url"
      placeholder="Web Site Bağlantısı"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'Ordering',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['references/dataItem']
    },
  },
}
</script>
